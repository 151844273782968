.public-form {
  margin-top: 2rem;

  .hidden-ant-form-item-label .ant-form-item-label {
    display: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4.375rem;

    & img.logo {
      max-width: 180px;
      max-height: 120px;
    }

    @include screen("tablet") {
      padding: 0 2.375rem;
    }

    @include screen("phone") {
      flex-direction: column;
      align-items: flex-start;
    }

    @include screen("phone") {
      padding: 0;
    }
  }

  &__heading {
    margin-left: unset;

    @include screen("phone") {
      padding-left: 0;
    }
  }

  &--go4rent-theme {
    a {
      color: $color-g4r-red-shade;
    }
  }

  &__description-box {
    font-size: 0.9375rem;
    text-align: right;

    @include screen("phone") {
      text-align: left;
    }
  }

  .form {
    input,
    textarea {
      &::placeholder {
        color: $color-input-border-not-active;
      }
    }

    .ant-select-selection-placeholder {
      color: $color-input-border-not-active;
    }

    .ant-col.monthly-payment-col {
      .ant-row {
        margin: 1rem 1rem 2rem 0;
        line-height: 2.5rem;
      }

      .ant-col {
        width: 50%;
      }

      label {
        font-size: 0.8125rem;
      }

      div.monthly-payment {
        float: right;
        border-radius: 1.25rem;
        background-color: #e996b430;
        padding: 0 1rem;
        font-weight: bold;
        color: $color-g4r-red;
        border-bottom: 0.0625rem solid #e996b430;
      }
    }
  }

  &__form-block {
    @include box-shaddow-thin;
    padding-left: 4.375rem;
    padding-right: 4.375rem;
    padding-bottom: 6.25rem;
    padding-top: 2.375rem;
    margin-top: 2rem;

    @include screen("phone") {
      padding-left: 2.375rem;
      padding-right: 2.375rem;
      padding-bottom: 4.25rem;
      padding-top: 1.375rem;
    }

    .invoice-verification-confirmation__header {
      margin-bottom: 2.3125rem;
      margin-top: 0.625rem;
      color: $color-txt-black-notfound;
    }
  }

  &__comment {
    &--go4rent-theme {
      a {
        color: $color-g4r-red-shade;
      }
    }
  }

  &__checkbox {
    display: flex;
    align-items: flex-start;

    .ant-checkbox {
      margin-top: 0.3rem;
    }
  }

  &__comment-icon {
    margin-left: 0.75rem;
    font-size: "1rem";
    & svg {
      color: $color-g4r-red;
    }
  }

  &__state-icon svg {
    color: $color-g4r-red-shade;
  }
}
