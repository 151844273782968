.textarea {
  &:focus,
  &.textarea--active {
    border-bottom-color: $color-labels-active;
  }

  &::placeholder {
    color: $color-txt-black;
  }

  @include default-bottom-border;

  resize: none;
  overflow: hidden;
  padding-left: unset;
  box-shadow: none;
  color: $color-txt-black;
  -webkit-box-shadow: unset;
}
