.upload-invoices-wrapper {
  border-bottom: 1px solid $color-border-user-menu;
  margin-bottom: 0.625rem;
}

.uploadInvoices {
  &.button {
    padding-left: 2.813rem;
    padding-right: 3.125rem;
  }

  bottom: 1.2rem;
  font-size: 0.9375rem;
  // background-image: url("./../../../images/icon_download.png");
  background-repeat: no-repeat;
  background-position: right 1.6rem center;
  float: right;

  &:hover,
  &:active,
  &:focus {
    // background-image: url("./../../../images/icon_download.png");
    background-repeat: no-repeat;
    background-position: right 1.6rem center;
  }

  a {
    margin-top: -0.125rem;
    display: block;
  }
}

.private-content-wrapper {
  overflow: inherit;
  padding-top: 2.3rem;
}

.ant-layout.ant-layout-has-sider > .ant-layout.private-content-wrapper {
  overflow: inherit;
}

.main {
  width: 97.1875rem;
  min-width: 97.1875rem;
  margin: 0 auto;
}

.no-contracts {
  height: 100%;

  &__block {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
