.form {
  .ant-form-item {
    .ant-col {
      width: 100%;
      flex: auto;
      margin-right: 1rem;
    }

    .ant-form-item-label {
      label {
        float: left;
        font-size: 0.8125rem;
        line-height: 0.938rem;
        min-height: 0.938rem;
        height: auto;
        color: $color-labels-txt;
      }

      > label::after {
        content: unset;
      }
    }

    label.ant-form-item-required::before {
      display: none;
    }
  }

  label {
    font-size: 0.6875rem;
  }

  .ant-form-item-has-error {
    .ant-input {
      color: $color-error;
      border-color: $color-error;

      &:focus {
        box-shadow: unset;
        -webkit-box-shadow: unset;
      }

      &::placeholder {
        color: $color-error;
        border-color: $color-error;
      }
    }
  }
}

.data-form {
  input {
    font-size: 0.9375rem;
    color: $color-txt-black;
  }

  .ant-form-item-label {
    > label {
      color: $color-labels-txt;
    }
  }

  .ant-form-item-has-error {
    .ant-input {
      color: $color-error;
      border-color: $color-error;

      &:focus {
        box-shadow: unset;
        -webkit-box-shadow: unset;
      }
    }

    .ant-form-item-label > label {
      color: $color-error;
    }
  }
}
