.fileUploadBlockWrapper {
    padding-bottom: 2rem;
}
.dragAndDrop {
  cursor: pointer;
  max-height: fit-content;
  max-width: 16rem;

  &.shakeOnDrag {
    animation: shake 1s;
    animation-iteration-count: infinite;
  }

  .errorMessage {
    color: $color-icon-minus;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    word-wrap: break-all;
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-2px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(2px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-2px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(2px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
}
.dragAndDropChildrenWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px dashed $color-txt-black;
  padding: 0.5rem 1rem;

  .anticon-cloud-upload {
    color: $color-drag-highlighter;
    font-size: 2rem;
  }

  h5 {
    color: $color-drag-highlighter;
  }

  .dragAndDropChildrenWrapper__button {
    background-color: $color-button-file-upload;
    border-color: $color-button-file-upload;
    color: $color-txt-black;
  }

  &:hover {
    .dragAndDropChildrenWrapper__button {
      background-color: $color-drag-highlighter;
      border-color: $color-drag-highlighter;
      color: $color-background;
    }
  }
}

.fileUploadBlock {
  .fileInput {
    visibility: hidden;
  }

  .ant-select {
    border-bottom: 0.0625rem solid $color-input-border-not-active;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 1.88rem;
  }

  .minusIconWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0.7rem 0;

    .anticon-minus-circle {
      font-size: 1.5rem;
      padding: 0.2rem;
      color: $color-button-file-upload;

      &:hover {
        color: $color-icon-minus;
      }
    }
  }
}
