.currency-number {
  border-left: unset;
  border-right: unset;
  border-top: unset;

  @include default-bottom-border;

  &::placeholder {
    color: $color-txt-black;
  }

  &:focus {
    border-bottom-color: $color-txt-black;
  }
}

.ant-input-number-input {
  padding-left: unset;
  padding-right: unset;
}
