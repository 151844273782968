.delete-contact {
  .checkbox-row {
    margin: 2rem 0 3rem;

    span {
      font-size: 1.1625rem;
    }
  }

  > div {
    > div:last-child {
      clear: both;
    }
  }
}

.delete-buyer {
  .reason {
    padding-top: 3rem;
    padding-bottom: 2rem;
    label {
      padding-top: 3rem;
      padding-bottom: 1.5rem;
      font-size: 1.5rem;
    }

    textarea {
      padding-left: unset;
      line-height: 1.5rem;
      font-size: 1.1625rem;
      margin-top: 2rem;
    }
  }
}

.block.basicContractInfo {
  height: 100%;
  border-radius: 0.625rem;
  overflow: hidden;
  padding: 0;
  @include box-shaddow-thin;

  .type-name {
    text-transform: capitalize;
  }

  .contractStatus {
    position: absolute;
    right: 2.5rem;
    top: 1.5625rem;

    h2 {
      text-transform: capitalize;
      margin: 0;
      font-size: 1.0625rem;
      font-weight: unset;

      &.activeStatus {
        color: $color-border-invoice-status-paid;
      }

      &.expiredStatus {
        color: $color-border-invoice-status-financed;
      }

      &.suspendedStatus {
        color: $color-border-invoice-status-overdue;
      }

      &.otherStatus {
        color: $color-border-invoice-status-submitted;
      }
    }
  }

  .basicContractDetails {
    margin-top: 12%;
  }
}

.ContractStatisticsDonut {
  margin-left: 1rem;
  padding: 1.3125rem 2.1875rem;
  width: 100%;
  border-radius: 0.625rem;
  overflow: hidden;

  @include box-shadow-donut-thin;

  > .plate + svg {
    position: absolute;
    z-index: -1;
  }

  .legend-cont {
    > * {
      display: block;
    }
  }

  h3 {
    margin-bottom: 0;
    font-weight: normal;
  }

  .ant-row {
    .ant-col:first-child {
      text-align: left;
    }
    .ant-col:last-child {
      text-align: right;

      > div {
        width: 100%;
      }
    }
  }

  .donut-wrapper {
    position: absolute;
    width: 15.2375rem;
    top: 3.28125rem;
    left: 4.4375rem;
  }

  .VictoryContainer {
    text {
      display: none;
    }
  }

  .donutData {
    .ant-col {
      height: 9.8125rem;
      min-height: 9.8125rem;

      &.legend-available,
      &.legend-overdue {
        height: 9.375rem;
        min-height: 9.375rem;
      }
    }

    &.verticalBottom {
      .ant-col {
        > div {
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
}

.contractListCollapse {
  .contractFilterSingle {
    .contractsSelectMenu {
      > .ant-row {
        width: 70.020625rem;
      }
    }
  }

  .ContractFilter {
    .contractsSelectMenu {
      top: 0;
    }
  }
}

// Contract selector options.
.contractFilterSingle,
.block .contractFilterSingle {
  padding: 0;

  .headerMenu {
    button {
      top: 0.75rem;
      right: 0.875rem;
    }
  }

  .contractsSelectMenu {
    .ant-row {
      cursor: pointer;

      .ant-col {
        padding: 0.719rem 0.3125rem 0.719rem 0;
      }
    }

    &.active-true {
      .ant-row .ant-col:first-child {
        border-bottom: 0.0625rem solid $color-tables-border-not-active;
      }

      .ant-row:last-child .ant-col:first-child {
        border-bottom: unset;
      }
    }
  }
}

// Specific fixes.
.Bills-page,
.Reports-page {
  .ContractSelector {
    margin: 1.175rem 0 0.4rem 0;
    cursor: pointer;
  }

  .ContractFilter {
    margin: 1.175rem 0 0rem 0;
    padding: 0;
    cursor: pointer;
  }

  .contractFilterSingle.ContractFilter {
    margin: 0;
  }
}

.Reports-page {
  .ContractSelector {
    margin-bottom: 0.8rem;

    .contractsSelectMenu {
      margin-top: -0.1875rem;
    }
  }
}

// End of contract selector options.

.contractListCollapse {
  background-color: $color-background;
  padding: 1rem 2rem 2rem 2rem;
  margin-top: 1.175rem;

  .ContractSelector {
    padding: 0;

    h4 {
      display: none;
    }

    .contractFilterSingle {
      margin: 0 0 1rem 0;
      box-shadow: unset;
    }
  }

  .basicContractInfo {
    p {
      color: $color-txt-black;
      font-size: 1.0625rem;
    }
  }

  .basicContractInfo {
    > .ant-row {
      padding: 1.6875rem 2.5rem 2.5rem 2.5rem;

      &.contractGrayBox {
        padding-top: 4.75rem;
        padding-bottom: 1.3125rem;
      }
    }

    .ant-col {
      p {
        margin-top: -0.1875rem;
        margin-bottom: 0.625rem;
      }
    }
  }

  .prolong-info {
    .ant-col {
      padding: unset;
      padding: 0;

      button {
        font-size: 0.8125rem;
        height: 1.875rem;
        padding: 0 1rem;
        margin-left: 1rem;
      }
    }
  }

  .padding-after-border {
    padding-left: 3rem;
  }
}

.contractGrayBox {
  &.activeStatus {
    background-color: $bg-invoice-status-paid;
  }

  &.expiredStatus {
    background-color: $bg-invoice-status-financed;
  }

  &.suspendedStatus {
    background-color: $bg-invoice-status-overdue;
  }

  &.otherStatus {
    background-color: $bg-invoice-status-submitted;
  }

  &::before {
    display: block;
    position: absolute;
    width: 1.1875rem;
    height: 1.8125rem;
    content: "";
    top: 1.5625rem;
    background-color: $color-contact-icon;
    // -webkit-mask: url("./../icons/menu/icon_contracts.svg") no-repeat;
    // mask: url("./../icons/menu/icon_contracts.svg") no-repeat;
    mask-size: 1.1875rem 1.8125rem;
  }

  > .ant-col:last-child {
    padding-left: 3rem;
  }
}

html body .buyersList {
  h3 {
    float: left;
  }

  > button {
    float: right;
  }

  > button.buyersList__download-pdf {
    float: left;
    padding: unset;
    margin-left: 6rem;
    padding-bottom: 0.813rem;
  }

  table button {
    width: 1.1875rem;
    height: 1.1875rem;

    svg {
      width: 1.1875rem;
      height: 1.1875rem;
    }
  }

  table + button {
    margin-top: 2rem;
  }

  table {
    width: 100%;
    margin-top: 4rem;

    tr {
      cursor: pointer;
    }

    &.table-empty {
      tr {
        cursor: default;
      }
    }
  }

  th {
    width: 8.125rem;

    &.companyName {
      width: 17.5rem;
    }

    &.insuredFactoringLimit,
    &.uninsuredFactoringLimit,
    &.used,
    &.availableLimit {
      width: 10.375rem;
    }
    &.interestRate {
      width: 3.95rem;
    }
    &.dueTerm {
      width: 4.9rem;
    }

    &.insurance {
      width: 3.75rem;
    }
  }
}

.loan {
  .buyersList {
    table {
      tr {
        cursor: default;
      }
    }
  }
}

.contractFilterSingle {
  box-shadow: 0 0 3.6875rem -1.8rem rgba(0, 0, 0, 0.52);
  background-color: #fff;

  margin: 0.8rem 0;

  .anticon-down {
    background-image: url(/static/media/icon_arrow.5e1660f6.svg);
    width: 0.8115rem;
    height: 0.43625rem;

    background-repeat: no-repeat;

    svg {
      display: none;
    }
  }
}

body .increaseLimit {
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }

  .currency-number {
    width: 100%;
  }

  .currency-number,
  .currency-number::placeholder {
    font-size: 1.5625rem;
    color: $color-txt-black;
  }

  .ant-picker {
    padding-left: unset;
    padding-top: unset;
    padding-bottom: unset;
  }

  .ant-input-number {
    margin-top: 0.5rem;
    padding-bottom: 0.313rem;
  }

  .ant-upload-drag-hover {
    background-color: $color-report-checkbox-checked-bg;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-wrapper {
    margin-left: 1rem;
  }
  textarea {
    padding-left: unset;
    margin-top: 2rem;
  }

  .ant-row:not(:last-child) {
    padding-top: 1.875rem;
  }

  .ant-row:last-child {
    padding-top: 0.875rem;
  }

  .limits {
    p {
      font-size: 1.5625rem;
    }
  }

  p {
    font-size: 0.9375rem;
  }

  .uploadSupportingDocuments {
    p {
      margin-bottom: 0.3rem;
    }

    span {
      font-size: 0.938rem;
      color: $color-txt-black;
    }
  }

  .ant-col-1 {
    margin-right: -0.3rem;
    color: $color-txt-black;
  }
}

.contractFunction {
  .ant-upload.ant-upload-drag {
    margin-right: unset;
  }

  .fileSize {
    text-transform: capitalize;
  }

  .date-picker input {
    font-size: 1.5625rem;
  }
}

.newBuyer {
  .ant-form {
    width: 100%;
  }

  .ant-input-number-input {
    padding-left: unset;
    padding-right: unset;
  }

  .ant-input-group-addon .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
  }

  .ant-collapse {
    background-color: unset;

    .ant-collapse-item {
      box-shadow: 0.25rem 0.625rem 1.313rem 0 rgba(0, 0, 0, 0.1);

      &.error:not(.ant-collapse-item-active) {
        background-color: $color-contract-overdue-bg;

        label {
          color: $color-contract-overdue-txt;
        }

        .panel-header__text {
          color: $color-contract-overdue-txt;
        }
      }

      .ant-form-item-control {
        flex-basis: 100%;
      }

      .ant-collapse-header {
        .panel-header {
          display: flex;

          &__item {
            display: flex;
            flex-direction: column;
            width: 45%;
          }

          &__text {
            font-size: 0.938rem;
          }
        }
      }

      .panel-footer {
        background-color: $color-background-block;
        margin: 0 -1rem -1rem;
        padding: 3rem 1rem;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  h3 {
    margin-top: 2rem;
    color: $color-txt-black;

    &:first-of-type {
      margin-top: 3.6rem;
    }
  }

  .ant-input-number {
    width: 100%;
    border: 0 none;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  label {
    color: $color-labels-txt;
    font-size: 0.8125rem;
  }

  .ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      padding-left: unset;
      height: 1.8125rem;
    }
  }

  .ant-input-group-addon {
    .ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        padding-left: unset;
        height: 1.9rem;
      }
    }
  }

  input,
  input::placeholder,
  .ant-select-selection-placeholder {
    color: $color-txt-black;
    font-size: 0.9375rem;
    opacity: 1;
  }

  .ant-form-item-label {
    padding-bottom: unset;
    label {
      height: 0.6875rem;
    }
  }
}

.document-sign {
  top: 0.5rem;

  .ant-modal-content {
    width: auto;

    .ant-modal-body {
      padding-left: 1rem;
      padding-top: unset;
      padding-bottom: unset;
    }
    .ant-modal-footer {
      padding-bottom: 4.75rem;
      padding-top: unset;

      .ant-btn:first-child {
        margin-left: 2rem;
      }

      &::after {
        background-color: green;
      }
    }
  }
}
