.header {
  margin-top: 1.875rem;

  > div {
    padding-bottom: 1.25rem;
    border-bottom: 0.063rem solid $color-border-user-menu;
  }

  .logo-wrapper {
    margin-left: 3.9375rem;
    display: flex;
    align-items: center;

    .logo {
      display: block;

      img {
        height: 1.5rem;
        width: 11.688rem;
      }
    }
  }

  &__wrapper {
    margin-left: 3.9375rem;
    width: 97.1875rem;
    min-width: 97.1875rem;
    margin: 0 auto;
  }
}
