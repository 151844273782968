.buyer-details {
  @include box-shaddow-thin;
  background-color: $color-background;
  padding: 2rem;
  margin-top: 1.175rem;
  border-radius: 0.625rem;

  &__header {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;

    &__heading {
      font-size: 0.9375rem;
      font-weight: 400;
      margin-bottom: 0;
    }

    &__back-button {
      background-image: url("../../icons/icon_arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 0.625rem 0.375rem;
      background-color: transparent;
      border: 0;
      padding: 0.7rem;
      transform: rotate(90deg);
      margin-right: 1.5rem;
      cursor: pointer;
    }
  }

  &__information-box {
    @include box-shaddow-thin;
    background-color: $color-background-block;
    padding: 1.5rem 2.1875rem 3rem;

    &__header {
      margin-bottom: 3.75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__heading {
        font-size: 1.3125rem;
        font-weight: 400;
        margin-bottom: 0;
      }

      &__action-button {
        background-repeat: no-repeat;
        background-position: center;
        background-size: 1.0625rem;
        background-color: transparent;
        border: 0;
        padding: 1rem;
        opacity: 0.5;
        transition: opacity 0.3s ease-out;
        cursor: pointer;
        margin-right: 0.5rem;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          opacity: 1;
        }

        &--edit {
          background-image: url("../../icons/icon_edit.svg");
        }

        &--delete {
          background-image: url("../../icons/icon_delete.svg");
        }
      }
    }

    &__column {
      padding-left: 2.5rem;
      border-right: 0.1rem solid rgba($color: #000000, $alpha: 0.1);

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border-right: 0;
      }
    }

    &__container {
      margin-bottom: 2.375rem;

      &:last-child {
        margin-bottom: 0;
      }

      p {
        margin: 0;
        color: $color-txt-black;
      }
    }
  }

  &__contacts-container {
    margin-top: 3rem;
    padding-left: 2rem;
    padding-bottom: 5.125rem;

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3rem;

      &__heading {
        font-size: 1.3125rem;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    &__table.ant-table-wrapper {
      margin: 0;
      padding: 0;

      th.ant-table-cell {
        padding-left: 0;
      }
    }
    &__table__tooltip {
      > div {
        div:first-child {
          right: 0.625rem;
          display: block;
          &::before {
            background-color: $color-sme-input-icons;
          }
        }
        div:last-child {
          font-size: 0.8125rem;
          border: 0.063rem solid $color-sme-input-icons;
          color: $color-sme-input-icons;
        }
      }
    }

    &__table__actions {
      text-align: right;

      &__button {
        background-repeat: no-repeat;
        background-position: center;
        background-size: 1.0625rem;
        background-color: transparent;
        border: 0;
        padding: 1rem;
        opacity: 0.5;
        transition: opacity 0.3s ease-out;
        cursor: pointer;
        margin-right: 0.5rem;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          opacity: 1;
        }

        &--edit {
          background-image: url("../../icons/icon_edit.svg");
        }

        &--delete {
          background-image: url("../../icons/icon_delete.svg");
        }

        &.disabled {
          cursor: default;
          opacity: 0.5;
        }
      }
    }
  }

  &__back-button {
    display: block;
    margin-top: 1.25rem;
    margin-left: auto;
  }
}
