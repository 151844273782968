.ant-form-item-has-error .ant-input:focus {
  box-shadow: unset;
}

.ant-form-item-has-error .ant-input::placeholder {
  color: $color-error;
}

.ant-input-search {
  &.ant-input-affix-wrapper > input.ant-input,
  &,
  input {
    border-bottom: unset;
  }
}

select:focus,
.ant-input-affix-wrapper-focused .ant-input-suffix {
  border-bottom-color: $color-txt-black;
}
