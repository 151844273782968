.contract-sign {
  &__item {
    @include box-shaddow-thin;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2.2rem;
    background-color: $color-background;
    border-radius: 0.625rem;
    margin-bottom: 0.9375rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__modal {
    .ant-modal-content {
      width: 100%;
    }

    &--dokobit {
      .ant-modal-body {
        padding: 0;
      }

      iframe {
        width: 100%;
        height: 82.5vh;
        border: 0;
      }

      .ant-modal-close-x {
        display: block;
      }
    }
  }

  &__label {
    font-size: 0.9375rem;
    color: $color-txt-black;
    margin: 0 0 0 1rem;
  }
}
