// Reset ant styles.
html body {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: unset;
  }

  .ant-input[disabled] {
    background-color: initial;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused,
  .ant-picker-focused,
  .ant-select-focused,
  .ant-select-selector {
    box-shadow: unset;
    outline: unset;
  }

  .ant-collapse {
    border: unset;

    .ant-collapse-item {
      overflow: hidden;
      margin: 0.9375rem 0;
      border-radius: 0.625rem;
      border-bottom: unset;
      background-color: $color-background;

      &:last-child {
        border-radius: 0.625rem;
      }

      .ant-collapse-header {
        padding-left: 3.4rem;
        font-size: 1.3125rem;
        padding-left: 3.4rem;
        padding-top: 2rem;

        &[aria-expanded="false"] {
          padding-top: 1.875rem;
          padding-bottom: 1.875rem;
        }
      }

      .ant-collapse-content {
        border-top: unset;
      }
    }
  }

  .ant-layout {
    background-color: transparent;
  }
}

.invoiceUpload .ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}

.ant-form-item-label {
  > label.ant-form-item-required::before {
    display: none;
  }
}
