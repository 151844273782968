@mixin box-shadow {
  -webkit-box-shadow: 0 0 3.6875rem -1.5rem rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 0 0 3.6875rem -1.5rem rgba(0, 0, 0, 0.52);
  box-shadow: 0 0 3.6875rem -1.5rem rgba(0, 0, 0, 0.52);
}

@mixin box-shaddow-thin {
  -webkit-box-shadow: 0 0.8125rem 1.125rem 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0.8125rem 1.125rem 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0.8125rem 1.125rem 0 rgba(0, 0, 0, 0.05);
}

@mixin box-shadow-donut-thin {
  -webkit-box-shadow: 0 0.375rem 2.0625rem -0.375rem rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0.375rem 2.0625rem -0.375rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.375rem 2.0625rem -0.375rem rgba(0, 0, 0, 0.1);
}

@mixin border-top {
  border-top: 0.0625rem solid $color-input-border-not-active;
}

@mixin border-bottom-tables {
  border-bottom: 0.0625rem solid $color-tables-border-not-active;
}

@mixin colored-right-border($border-color) {
  border-right: 0.5rem solid $border-color;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

@mixin default-bottom-border {
  border-bottom: 0.0625rem solid $color-input-border-not-active;
}

$phone: 30rem;
$tablet: 47.938rem;
$small-desktop: 62rem;
$large-desktop: 75rem;

@mixin screen($size) {
  @if $size == "phone" {
    @media screen and (max-width: $phone) {
      @content;
    }
  } @else if $size == "tablet" {
    @media screen and (max-width: $tablet) {
      @content;
    }
  } @else if $size == "small-desktop" {
    @media screen and (max-width: $small-desktop) {
      @content;
    }
  } @else if $size == "large-desktop" {
    @media screen and (max-width: $large-desktop) {
      @content;
    }
  } @else {
    @content;
  }
}
