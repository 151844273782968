.grid {
  .ant-table-content {
    th:not(.ant-table-selection-column) {
      padding-left: unset;
      text-overflow: unset;
      white-space: break-spaces;
      line-height: 1rem;
    }

    th.status-column {
      border-bottom: unset;
    }

    td.status-column {
      border-bottom: unset;
      width: 0.4375rem;

      &::after {
        display: block;
        position: absolute;
        width: 0.5rem;
        content: "";
        height: 100%;
        top: -0.0625rem;
        border-right: 0.5rem solid $color-white-txt;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    }
  }
}

.table {
  table {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
  &.table-empty {
    table {
      tbody {
        tr {
          cursor: default;
        }
      }
    }
  }
}
