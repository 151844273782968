$color-black: #000;
$color-input-border-not-active: #cccccc;
$color-background-block: #f9f9f9;
$color-error: #ff002a;

@mixin default-bottom-border {
    border-bottom: 0.0625rem solid $color-input-border-not-active;
}

.phone-number-wrapper {
    .phone-number-input {
  
      border-bottom: 0.0625rem solid $color-input-border-not-active;
  
      .ant-form-item {
        min-width: auto;
  
        div.ant-col.ant-form-item-label {
          padding: 0;
        }
      }
  
      .ant-input-group .ant-input-group-addon .ant-select-show-arrow {
        &.phone-code {
          width: 6.5rem;
  
          input {
            border-bottom: unset;
          }
        }
      }
  
      .ant-form-item {
        margin-bottom: 0;
      }
  
      .ant-select {
        background-color: $color-background-block;
        margin-right: 0.625rem;
        border-bottom: none;
        margin-right: -1rem;
        font-size: 0.9rem;
  
        .ant-select-selector {
          height: 1.9rem;
          padding: 0;
        }
  
        .ant-select-selection-placeholder {
          color: $color-black;
          opacity: 1;
        }
  
        .ant-select-dropdown {
          width: 12rem !important;
        }
      }
  
      .ant-select-arrow {
        top: 60%
      }
  
      .ant-input-group-addon {
        border: 0 none;
        padding-right: 0;
      }
  
      input {
        @include default-bottom-border;
        font-size: 0.9375rem;
        height: 1.9rem;
        padding-left: 0.5rem;
        border-bottom: none;
  
        &::placeholder {
          color: $color-black;
        }
  
      }
  
      &::-webkit-input-placeholder {
        /* Edge */
        color: $color-black;
      }
  
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $color-black;
      }
  
      &::placeholder {
        color: $color-black;
      }
    }
  
    .hide {
      visibility: hidden;
      height: 0;
      padding: 0;
      display: block;
      position: absolute;
      top: -1000
    }
  
    .input-active-border {
      border-bottom-color: $color-black;
    }
  
    .ant-form-item-has-error {
      .input-active-border {
        border-bottom-color: $color-error;
        color: $color-error;
      }
    }
  }
  
  .ant-select-dropdown {
    width: 24em !important;
  }