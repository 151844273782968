.ant-checkbox-wrapper,
.checkbox-row {
  span {
    font-size: 0.9375rem;
    color: $color-txt-black;
  }
}

.checkbox {
  &:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $color-g4r-red;
  }

  .ant-checkbox-checked {
    &:after {
      border-color: $color-g4r-red;
    }

    .ant-checkbox-inner {
      background-color: $color-g4r-red;
      border-color: $color-g4r-red;
    }
  }
}
