.language {
  position: relative;

  &--responsive {
    @include screen("tablet") {
      .language-dropdown {
        padding: 4.438rem 1.813rem 3rem 1.813rem;

        .language-title {
          display: none;
        }
      }
    }
  }
}

.language-button {
  background-color: $color-background;
  color: $color-txt-black;
  border: 0;
  font-size: 0.938rem;
  height: 3.063rem;
  width: 3.063rem;
  border-radius: 50%;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0;
  z-index: 5;
  position: relative;
}

.language-dropdown {
  position: absolute;
  z-index: 4;
  top: -0.625rem;
  left: -0.875rem;
  padding: 71px 35px 48px 29px;
  background-color: $color-background;
  border-radius: 0.625rem;

  @include box-shadow;
}

.language-dropdown-row {
  margin-bottom: 14px;
  font-size: 13px;
  color: $color-txt-black;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    color: rgba($color-txt-black, 0.3);
  }
}

.language-abbr {
  text-transform: uppercase;
  margin-right: 0.625rem;
}
