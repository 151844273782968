.publicLayout {
  position: absolute;
  min-width: 97.1875rem;
  width: 100%;
  left: 0;
  top: 0;
  // background-image: url("../../images/bluebg.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;

  &.formLayout {
    position: static;
    min-width: unset;
    left: unset;
    top: unset;
    background-image: none;

    &.go4rent-theme header {
      background-color: $color-g4r-red;
    }

    header {
      padding-top: 4.125rem;
      background: $color-sme-blue;

      @include screen("tablet") {
        padding-top: 2.125rem;
      }

      p {
        display: none;
      }

      .ant-row {
        .headerContent {
          @include screen("tablet") {
            padding-bottom: 2.125rem;
          }
        }
      }
    }
  }

  .branding {
    padding-bottom: 5rem;
    height: 12.8125rem;
    position: relative;

    img {
      position: absolute;
      bottom: 3.125rem;
    }

    p {
      font-size: 1.3125rem;
      color: $color-white-txt;
      position: absolute;
      bottom: 1.225rem;
      right: 0;
    }
  }

  header {
    padding-top: 8.3rem;
    .ant-row {
      padding-bottom: 0 !important;
      color: $color-white-txt;

      .headerContent {
        padding-bottom: 5rem;
        display: flex;
        align-items: center;

        p {
          margin: 0 1.625rem 0 auto;
          position: absolute;
          right: 2.8125rem;
          max-width: 33.125rem;
        }

        .back {
          position: absolute;
          right: 2.8125rem;
          font-size: 0.8125rem;
          color: $color-white-txt;
          text-transform: lowercase;
          margin-right: 1.625rem;
        }

        .language {
          margin-left: auto;
        }
      }
    }
  }
}
