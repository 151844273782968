$button_primary: #2b63f4;
$button_secondary: #c6d3fb;
$primary_color: #ffffff;
$secondary_color: #000000;
$button_hover: #e9f0fe;

$theme_go4rent: #db435c;
$theme_go4rent_text_hover: #363435;
$theme_go4rent_hover: #fbecee;

button.button {
  padding: 0 1.9375rem;
  font-size: 0.9375rem;
  line-height: 1;
  border-radius: 1.5625rem;
  box-shadow: none;
  text-shadow: none;

  &--primary {
    background-color: $button_primary;
    border-color: $button_primary;
    color: $primary_color;

    &:hover:not(.button--no-hover) {
      background-color: $button_hover;
      border-color: $button_hover;
      color: $button_primary;
    }

    &:focus {
      background-color: $button_primary;
      border-color: $button_primary;
      color: $primary_color;
    }

    &[disabled] {
      background-color: rgba($color: $button_primary, $alpha: 0.3);
      color: $primary_color;
      border: none;

      &:hover {
        background-color: rgba($color: $button_primary, $alpha: 0.3);
        color: $primary_color;
        border: none;
      }
    }

    &.button--theme-go4rent {
      &.ant-btn.active,
      &.ant-btin:active,
      &:focus {
        background-color: $theme_go4rent;
        border-color: $theme_go4rent;
      }

      background-color: $theme_go4rent;
      border-color: $theme_go4rent;

      &:hover:not(.button--no-hover) {
        background-color: $theme_go4rent_hover;
        border-color: $theme_go4rent_hover;
        color: $theme_go4rent_text_hover;
      }

      &[disabled] {
        background-color: rgba($color: $theme_go4rent, $alpha: 0.3);
        color: $primary_color;
        border: 0.063rem solid rgba($color: $theme_go4rent, $alpha: 0.3);

        &:hover {
          background-color: rgba($color: $theme_go4rent, $alpha: 0.3);
          color: $primary_color;
          border: 0.063rem solid rgba($color: $theme_go4rent, $alpha: 0.3);
        }
      }
    }
  }

  &--default {
    background-color: transparent;
    border-color: $button_primary;
    color: $button_primary;

    &:hover:not(.button--no-hover) {
      background-color: $button_hover;
      border-color: $button_hover;
      color: $button_primary;
    }

    &:focus {
      background-color: transparent;
      border-color: $button_primary;
      color: $button_primary;
    }

    &[disabled] {
      border-color: rgba($color: $button_primary, $alpha: 0.3);
      color: rgba($color: $button_primary, $alpha: 0.4);
      background-color: transparent;

      &:hover {
        border-color: rgba($color: $button_primary, $alpha: 0.3);
        color: rgba($color: $button_primary, $alpha: 0.4);
        background-color: transparent;
      }
    }

    &.button--theme-go4rent {
      &.ant-btn.active,
      &.ant-btin:active,
      &:focus {
        border-color: $theme_go4rent;
        color: $theme_go4rent;
      }

      border-color: $theme_go4rent;
      color: $theme_go4rent;

      &:hover:not(.button--no-hover) {
        background-color: $theme_go4rent_hover;
        border-color: $theme_go4rent_hover;
        color: $theme_go4rent_text_hover;
      }

      &[disabled] {
        background-color: rgba($color: $theme_go4rent, $alpha: 0.3);
        color: $primary_color;
        border: 0.063rem solid rgba($color: $theme_go4rent, $alpha: 0.3);

        &:hover {
          background-color: rgba($color: $theme_go4rent, $alpha: 0.3);
          color: $primary_color;
          border: 0.063rem solid rgba($color: $theme_go4rent, $alpha: 0.3);
        }
      }
    }
  }

  &--secondary {
    background-color: transparent;
    border-color: $button_secondary;
    color: $secondary_color;

    &:hover:not(.button--no-hover) {
      background-color: $button_hover;
      border-color: $button_hover;
      color: $secondary_color;
    }

    &--active {
      background-color: $button_hover;
      border-color: $button_hover;
      color: $secondary_color;

      &:hover,
      &:focus {
        background-color: $button_hover;
        border-color: $button_hover;
        color: $secondary_color;
      }
    }
  }

  &--icon-only {
    padding: 0;
    outline: none;
    background-color: transparent;
    border: none;
    width: 2rem;
    height: 2rem;
    box-shadow: none;
  }

  &--large {
    height: 3.125rem;
  }

  &--small {
    height: 1.875rem;
    font-size: 0.8125rem;
  }
}
