button.ant-btn {
  padding-top: 1.0625rem;
  padding-bottom: 1.1875rem;
  height: 3.125rem;
  padding-left: 2.8125rem;
  padding-right: 3.125rem;
  border-radius: 1.5625rem;
}

.ant-btn-primary {
  background-color: $color-sme-blue;
  font-size: 1.1rem;
  line-height: 1;
}

.ant-btn-default {
  background-color: unset;
  border-color: $color-menu-item-not-active;
  color: $color-menu-item-not-active;
  font-size: 1.1rem;
  line-height: 1;
}

.loadMore {
  overflow: hidden;
  border-color: $color-cancel-button;

  &:hover,
  &:active,
  &:focus {
    border-color: $color-cancel-button;
  }

  span {
    font-size: 0.9375rem;
    line-height: 1;
    color: $color-cancel-button;
  }
}

.invoiceUpload {
  .ant-tabs-content {
    padding-left: 2rem;
  }

  .ant-tabs-tabpane-active {
    background: $color-main-background;
  }

  .ant-form-item:first-child {
    background: $color-white-txt;
  }

  margin-top: 1.175rem;
  .steps-action {
    button {
      span {
        height: auto;
      }
    }
  }
}

button:focus {
  outline: none;
}
