.top-menu {
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
  border-bottom: 0.0625rem solid $color-border-user-menu;
  padding-bottom: 0.8rem;

  a {
    font-size: 0.9375rem;
    margin-right: 2.6rem;
    color: $color-donut-p;
    padding-bottom: 0.9rem;
    border-bottom: 0.12rem solid transparent;

    &::before {
      display: inline-block;
      content: "";
      width: 1.0625rem;
      height: 1.0625rem;
      margin-right: 1rem;
      position: relative;
      top: 3px;

      background-color: $color-donut-p;
      // -webkit-mask-image: url("./../../../icons/menu/icon_dash.svg");
      // mask-image: url("./../../../icons/menu/icon_dash.svg");
      mask-repeat: no-repeat;
    }

    // &.menu-invoices::before {
    //   -webkit-mask-image: url("./../../../icons/menu/icon_invoices.svg");
    //   mask-image: url("./../../../icons/menu/icon_invoices.svg");
    // }

    // &.menu-reports::before {
    //   -webkit-mask-image: url("./../../../icons/menu/icon_reports.svg");
    //   mask-image: url("./../../../icons/menu/icon_reports.svg");
    // }

    // &.menu-bills::before {
    //   -webkit-mask-image: url("./../../../icons/menu/icon_bills.svg");
    //   mask-image: url("./../../../icons/menu/icon_bills.svg");
    // }

    // &.menu-contracts::before {
    //   -webkit-mask-image: url("./../../../icons/menu/icon_contracts.svg");
    //   mask-image: url("./../../../icons/menu/icon_contracts.svg");
    // }

    // &[href="/faq"]::before {
    //   -webkit-mask-image: url("./../../../icons/menu/icon_faq.svg");
    //   mask-image: url("./../../../icons/menu/icon_faq.svg");
    // }

    &.nav-active,
    &:hover {
      color: $color-labels-active;
      border-color: $color-labels-active;

      &::before {
        background-color: $color-labels-active;
      }
    }
  }
}
