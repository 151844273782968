.slider-col {
  .ant-form-item-label {
    margin-bottom: 0.5rem;
  }

  .ant-form-item {
    margin: 0;

    &.slider {
      margin-top: -0.4375rem;
    }
  }

  .ant-form-item-control-input {
    min-height: auto;
  }

  .ant-input-affix-wrapper {
    padding: 0 1.25rem;
    border-radius: 1.25rem;
    border-bottom: 0.0625rem solid $color-input-border-not-active;
    background-color: $bg-input-slider;
    line-height: 2.5rem;
    .ant-input {
      border: 0;
      color: $color-labels-active;
      font-weight: bold;
    }

    .ant-input-suffix {
      line-height: 2.5rem;

      &::before {
        display: inline-block;
        content: "";
      }
    }
  }

  .ant-slider {
    width: calc(100% - 2rem);
    flex: auto;
    padding: 0.375rem 0 0 0;
    margin: 0 0 0 1rem;
    z-index: 9999;

    .ant-slider-rail {
      background-color: $bg-input-slider-rail-inactive;
      height: 1px;
    }

    .ant-slider-track {
      background-color: $color-g4r-red;
      height: 1px;
    }

    .ant-slider-handle {
      background-color: $color-g4r-red;
      border: 0;
      width: 1.125rem;
      height: 1.125rem;
      top: 3px;
    }

    .ant-slider-dot {
      border: 0;
      background-color: $bg-input-slider-rail-inactive;
      top: -1px;
      width: 4px;
      height: 4px;
    }

    .ant-slider-dot-active {
      background-color: $color-g4r-red;
    }
  }

  .ant-row.min-max {
    color: $color-labels-txt;
    margin: 0.125rem 0 0.625rem 0;
    font-size: 0.813rem;

    .min-value {
      padding-left: 1rem;
    }

    .max-value {
      padding-right: 2rem;
      text-align: right;
    }
  }

  .ant-form-item-has-error {
    .ant-form-item-label label {
      color: $color-error;
    }

    .ant-form-item-explain {
      display: none;
    }
  }
}
